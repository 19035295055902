<template>
  <div id="home_container" class="outer-wrapper height-s-fix">
    <Transition name="image-cover">
      <!-- <img src="@/assets/images/bg-video.jpg" v-if="isGifLoading && !isMobile" alt="" class="img-fluid m-0 image-cover"> -->
      <div class="skeletonShine" style="display:block" v-if="isGifLoading && !isMobile"><div class="loader">Loading...</div></div>
    </Transition>
    <div class="" >
      <div class="row mx-0">
    
        <div class="ct-col col-lg-8 p-0 ">
        <div class="col-md-12">
          <Transition name="brand-logo" appear >
          <div class="brand-logo-mob text-left" v-if="!isMobile || (!isGifLoading && isMobile)">
            <router-link to="/"><img src="@/assets/images/nr-logo.png" width="100px" height="100px" alt="" class="img-fluid"></router-link>
          </div>
        </Transition>
        </div>
        <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top" v-show="!isGifLoading">
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" @click="toggleNav" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <div class="backdrop" @click="toggleNav"></div>
            <ul class="navbar-nav align-items-center gap-8">
              <li class="nav-item ">
                <a class="nav-link" @click="scrollToSection" @touchstart="scrollToSection" href="#highlights">Highlights</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" @click="scrollToSection" href="#amenities">Amenities</a>
              </li>
              <li class="nav-item">
               <a class="nav-link" @click="scrollToSection" href="#gallerySection">Gallery</a>
             </li>
             <li class="nav-item">
              <a class="nav-link" @click="scrollToSection" href="#videos">Videos</a>
            </li>
               <li class="nav-item">
                <a class="nav-link" @click="scrollToSection" href="#location">Location</a>
              </li>
              <!-- <li class="nav-item">
                <a class="nav-link" href="#" @click.prevent="downloadBrocher()">Download Brochure</a>
              </li> -->
              <li class="nav-item" v-if="isNewCustomer">
                <a class="nav-link  enquire-btn" @click="toggleNav" v-b-modal.enquiryForm>Enquire Now</a>
              </li>
            </ul>
          </div>
          </nav>
          <div class="plan-holder" v-show="!isGifLoading">
            <video autoplay muted disablePictureInPicture  loop id="heroVideoBg"><source src="https://s3.ap-south-1.amazonaws.com/enrichr.public.images/nirvana/bougainvilla_video_tour.mp4" type="video/mp4"></video>
          </div>
          <div class="about-new" v-show="!isGifLoading">
            <div class="col-12 title-container">
              <h3 class="font-weight-normal mb-2 ml-4">Nirvana Bougainvilla, Khopoli</h3>
            </div>
            <ProgressBar class="home-progress-bar mob-hide" :steps="formSteps" :activeStepNo="0"/>
            <EOIBenefits class="mb-3" v-if="isMobile"/>
            <div class="info-section mb-mob-20">
              <div class="info-wrapper">
                <div class="row m-0 w-100">
                  <div class="heading-box">  <h2 classs="main-heading"> About <span>Nirvana Bougainvilla, Khopoli</span></h2></div>
                  <div class="col-md-12 amenities-section">
                    <p class="pt-3">Nirvana Realty's latest project, Bougainvilla, is a villa township located in Khopoli, near Mumbai. Inspired by the Bougainvillea flower known for symbolizing peace and spiritual connection, the township is designed to reflect this philosophy through its Mediterranean-inspired architecture, landscape gardens, and spaces. Bougainvilla's unique theme of "flowers" sets it apart from other township projects, with careful curation of infrastructure to welcome natural light and air, creating a sustainable home that caters to current and future demands. The township's holistic lifestyle connects the mind, body, and soul, providing a tranquil and serene environment for residents.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="scroll-fix" id="highlights" ref="highlights"></div>
           <div class="info-section mb-mob-20" v-show="!isGifLoading">
            <div class="info-wrapper">
              <div class="row m-0 w-100">
               <div class="heading-box"> <h2 classs="main-heading">Project <span> Highlights</span></h2></div>
                <div class="col-md-12 amenities-section mb-5">
                
                  <div class="row mx-0 w-100 mt-3">
                    <div class="col-6 col-md-3">
                      <div class="highlights-box w-100 p-3">
                          <img src="@/assets/images/high-1.svg">
                          <p class="highlight-title">2, 3 & 4 Bed Villas with Private&nbsp;Pool</p>
                      </div>
                    </div>
                    <div class="col-6 col-md-3 ">
                      <div class="highlights-box w-100 p-3">
                          <img src="@/assets/images/high-2.svg">
                          <p class="highlight-title pr-0">Lifetime membership of My Club&nbsp;Nirvana</p>
                      </div>
                    </div>
                    <div class="col-6 col-md-3">
                      <div class="highlights-box lower-box w-100 p-3">
                          <img src="@/assets/images/high-3.svg">
                            <p class="highlight-title">20 Acres Development</p>
                        
                      </div>
                    </div>
                    <div class="col-6 col-md-3">
                      <div class="highlights-box lower-box w-100 p-3">
                          <img src="@/assets/images/high-4.svg">
                            <p class="highlight-title pr-0">Khopoli, Maharashtra Location</p>
                      </div>
                    </div>
                  </div>
                  <div class="row mx-0 w-100 mt-3">
                      <div class="col-md-6 highlights-box-ul">
                        <ul>
                            <li>20 Acres Development with 75% Open&nbsp;Spaces</li>
                            <li>2, 3 & 4 Bed Custom made Villas with Private&nbsp;Pool</li>
                            <li>Lifetime Membership of My Club Nirvana</li>
                            <li>60,000 sq.ft clubhouse | Indoor Games | 20 Seater Mini Theatre Jacuzzi Restaurant</li>
                            <li>Santorini styled Mediterranean Villas | Canal in the development with Mountain water | Sahyadri Mountains Views</li>
                        </ul>
                      </div>
                       <div class="col-md-6 highlights-box-ul">
                        <ul>
                          <li>Less Than 100 Bespoke Villas</li>
                          <li>No Maintenance charges | Guaranteed Rental&nbsp;Returns</li>
                          <li>Project Approved and Funded by Axis&nbsp;Bank</li>
                          <li>Situated near New Mumbai Goa Highway just 20&nbsp;minutes from Imagica towards Pali</li>
                        </ul>
                       </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div class="scroll-fix" id="amenities" ref="amenities"></div>
          <div class="info-section mb-mob-20"  v-show="!isGifLoading">
            <div class="info-wrapper">
              <div class="row m-0 w-100">
              <div class="heading-box"> <h2 classs="main-heading">Project <span> Amenities</span></h2></div>
                <div class="col-md-12 amenities-section pt-3">
                  <ul class="row align-order amenities-list">
                    <li class="col-12 col-md-6 pl-0 pb-1">Marigold Manor Clubhouse</li>
                    <li class="col-12 col-md-6 pl-0 pb-1">Butterfly Flower Garden</li>
                    <li class="col-12 col-md-6 pl-0 pb-1">Flower Shaped Swimming Pool</li>
                    <li class="col-12 col-md-6 pl-0 pb-1">Private pools with sundecks</li>
                    <li class="col-12 col-md-6 pl-0 pb-1">Meditation Centre</li>
                    <li class="col-12 col-md-6 pl-0 pb-1">Designer flooring and high-quality fittings</li>
                    <li class="col-12 col-md-6 pl-0 pb-1">Multi Cuisine Restaurant</li>
                    <li class="col-12 col-md-6 pl-0 pb-1">Common Two-wheeler Parking</li>
                    <li class="col-12 col-md-6 pl-0 pb-1">Daisy Dome Green House</li>
                    <li class="col-12 col-md-6 pl-0 pb-1">Modular kitchens equipped with modern appliances</li>
                    <li class="col-12 col-md-6 pl-0 pb-1">Kids Club</li>
                    <li class="col-12 col-md-6 pl-0 pb-1">Spacious balconies and terraces with breathtaking views</li>
                    <li class="col-12 col-md-6 pl-0 pb-1">Mini Golf Course</li>
                    <li class="col-12 col-md-6 pl-0 pb-1">Private gardens with landscaping and water features</li>
                    <li class="col-12 col-md-6 pl-0 pb-1">Gymnasium</li>
                    <li class="col-12 col-md-6 pl-0 pb-1">Botanical Garden</li>
                    <li class="col-12 col-md-6 pl-0 pb-1">Spacious living areas and bedrooms with large windows for natural light and fresh air</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="scroll-fix" id="gallerySection" ref="gallerySection"></div>
           <div class="info-section mb-mob-20" v-show="!isGifLoading">
            <div class="info-wrapper">
              <div class="row m-0 w-100">
               <div class="heading-box"> <h2 classs="main-heading">Photo <span>Gallery</span></h2></div>
                <div class="col-md-12 amenities-section px-3 mb-3">
                  <div class="row mx-0 w-100 mt-3">
                    <carousel ref="carousel" class="typology-carousel home" :perPage="2" :perPageCustom="[[576, 3], [1400, 4]]" :autoplay="true" :pagination-enabled="false" :navigation-enabled="true" :loop="true" :mouse-drag="false" :navigation-prev-label="prevLabel"  :navigation-next-label="nextLabel">
                      <slide v-for="slide in slideImages" :key="slide.key">
                        <a v-if="slide.image" :href="slide.image" data-fancybox="gallery">
                          <div class="gallery-img" :style="`background-image: url('${slide.image}'); `"></div>
                        </a>
                      </slide>
                    </carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="scroll-fix" id="videos" ref="videos"></div>
          <div class="info-section mb-mob-20"  v-show="!isGifLoading">
            <div class="info-wrapper">
              <div class="row m-0 w-100">
                <div class="heading-box">
                  <h2 classs="main-heading">Overview <span> Video</span></h2>
                </div>
                  <div class="col-md-12 amenities-section pl-3 site-visit-section mb-3">
                    <div class="row">
                      <div class="col-md-4 px-4 px-md-0 pl-md-2">
                        <div class="site-visit">
                          <div class="site-visit-strap text-center">
                            <img src="@/assets/images/play-btn.png" class="play-btn" v-b-modal.virtualTour>
                            <br><span class="site-visit-strap-text ">Watch Project Video</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 px-4 px-md-2">
                        <div class="site-visit location">
                          <div class="site-visit-strap text-center">
                            <img src="@/assets/images/play-btn.png" class="play-btn" v-b-modal.locationTour>
                            <br><span class="site-visit-strap-text ">Watch Location Video</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 px-4 px-md-0 pr-md-2">
                        <div class="site-visit tour">
                          <div class="site-visit-strap text-center">
                            <img src="@/assets/images/play-btn.png" class="play-btn" v-b-modal.flatTour>
                            <br><span class="site-visit-strap-text ">Watch Sample Flat Tour</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="scroll-fix" id="location" ref="location"></div>
          <div class="info-section mb-mob-20"  v-show="!isGifLoading">
            <div class="info-wrapper">
              <div class="row m-0 w-100">
                <div class="heading-box"> <h2 classs="main-heading">Project  <span> Location</span></h2></div>
                <div class="col-md-12 amenities-section mb-3 map-section">
                    <ProjectLocation/>
                    <h2 class="px-2 pt-3 mb-3 location-subtitle">Location Advantage</h2>
                    <ul class="row align-order amenities-list px-4 mb-1">
                      <li class="col-12 col-md-6 pl-0 pb-1">Imagica (20 Km)</li>
                      <li class="col-12 col-md-6 pl-0 pb-1">Ashtavinayak Ballaleshwar Temple (7 Km)</li>
                      <li class="col-12 col-md-6 pl-0 pb-1">Sarasgad Fort (7.2 km)</li>
                      <li class="col-12 col-md-6 pl-0 pb-1">Kolad River Rafting (40 km)</li>
                      <li class="col-12 col-md-6 pl-0 pb-1">Padsare Waterfall (20 km)</li>
                      <li class="col-12 col-md-6 pl-0 pb-1">Upcoming International Airport (40 km)</li>
                    </ul>
                    <a href="https://maps.google.com/maps?ll=18.576313,73.264938&z=14&t=m&hl=en&gl=IN&mapclient=embed&cid=13831966907942989688" target="_blank" class="btn-map px-2">
                      <svg class="mb-1 mr-1" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path d="M7 0.875C5.72409 0.876447 4.50085 1.38394 3.59865 2.28615C2.69644 3.18835 2.18895 4.41159 2.1875 5.6875C2.1875 9.80547 6.5625 12.9155 6.74898 13.0457C6.82255 13.0972 6.91018 13.1249 7 13.1249C7.08982 13.1249 7.17745 13.0972 7.25102 13.0457C7.4375 12.9155 11.8125 9.80547 11.8125 5.6875C11.8111 4.41159 11.3036 3.18835 10.4014 2.28615C9.49915 1.38394 8.27591 0.876447 7 0.875ZM7 3.9375C7.34612 3.9375 7.68446 4.04014 7.97225 4.23243C8.26003 4.42472 8.48434 4.69803 8.61679 5.0178C8.74924 5.33757 8.7839 5.68944 8.71637 6.02891C8.64885 6.36838 8.48218 6.6802 8.23744 6.92494C7.99269 7.16968 7.68087 7.33635 7.34141 7.40387C7.00194 7.4714 6.65007 7.43674 6.3303 7.30429C6.01053 7.17184 5.73722 6.94753 5.54493 6.65975C5.35264 6.37196 5.25 6.03362 5.25 5.6875C5.25 5.22337 5.43437 4.77825 5.76256 4.45006C6.09075 4.12187 6.53587 3.9375 7 3.9375Z" fill="#2B2D42"/>
                      </svg>
                       <span>View on Google Maps</span>
                    </a>
                </div>
              </div>
            </div>
          </div>
          
          <div class="info-section tnc" v-show="!isGifLoading">
            <div class="info-wrapper mb-5">
              <div class="row m-0 ">
                <div class="col-md-12 amenities-section terms-section">
                  <h2 class="headding">Terms and  Conditions</h2>
                  <p>Important things to keep in mind</p>
                  <h2 class="headding">Understanding EOI</h2>
                  <ul class="amenities-list">
                    <li class="col-12 col-md-12 my-0 ml-0 pl-0">Generation of EOI is a necessary but not the only step towards booking a property at Nirvana Bougainvilla - Khopoli</li>
                    <li class="col-12 col-md-12 my-0 ml-0 pl-0">Only one property will be allocated against a unique EOI number</li>
                    <li class="col-12 col-md-12 my-0 ml-0 pl-0">The EOI generated in one's name is non-transferable and needs to be mandatorily in the name of the individual who will be an applicant in the final home registration</li>
                  </ul>
                </div>
                <div class="col-md-12 amenities-section">
                  <h2 style='color: #000000' class="mt-3 headding">Allotment Rights</h2>
                  <ul class="amenities-list">
                    <li class="col-12 col-md-12 my-0 ml-0 pl-0">Allotment of a home is at the sole discretion of Nirvana Bougainvilla - Khopoli and is subject to availability at the time of allotment</li>
                  </ul>
                </div>
                <div class="col-md-12 amenities-section">
                  <h2 style='color: #000000' class="mt-3 headding">Terms Conditions for EOI</h2>
                  <ol class="amenities-list">
                    <li class="col-12 col-md-12 my-0 ml-0 pl-0">
                      a. The Promoter means <span class="text-underline-em">Nirvana Realty</span>, a Company incorporated under the Companies Act, of 1956.<br/>
                      b. The term &quot;Premises means and includes Flat as the case may be for which Expression of Interest (EOI) is made.<br/>
                      c. Applicant means any person expressing Interest to purchase the premises and if the context so requires, includes his/her agents or authorized representatives al.<br/>
                      d. Pre Allotment money means the minimum amount of money payable by the Applicant before considering the application for allotment.<br/>
                    </li>
                    <li class="col-12 col-md-12 my-0 ml-0 pl-0">
                      This EOI constitutes an offer by the Applicant to acquire Premises as mentioned in the EOI. The EOI does not confer or constitute any right in favor of the Applicant of allotment and or agreement for the acquisition of the Premises
                    </li>
                    <li class="col-12 col-md-12 my-0 ml-0 pl-0">The completed and duly signed EOl Form is to be submitted to the Promoter along with the Demand Draft Bank Draft/Pay Order/Cheque in favor of  &quot;<span class="text-underline-em">Nirvana Realty</span>&quot; towards EOI money as shown in the Price and Payment Schedule.</li>
                    <li class="col-12 col-md-12 my-0 ml-0 pl-0">On payment of the Pre Allotment Money by the Applicant and fulfillment of necessary formalities, on <span class="text-underline-em">31st December 2023</span> Promoter propose to open the booking of the premises. Within 15 days from that day, the Applicant will have to option to book the Premises by filing a booking application.</li>
                    <li class="col-12 col-md-12 my-0 ml-0 pl-0">Upon Applicant booking the premises, the Pre Allotment Money shall be treated as part of earnest money, which will be adjusted against the total consideration payable for the sale of Premises.</li>
                    <li class="col-12 col-md-12 my-0 ml-0 pl-0">The Applicant agrees to make a payment due as per the progress of construction, as stated in the payment plan, along with Stamp duty, Registration charges, and GST as applicable and execute the Agreement for Sale as may be drawn up by Promoter under applicable provisions of law/s, within <span class="text-underline-em">15 days</span> from the allotment of Premises and if the applicant falls to get the agreement registered for any reason, Promoter will not be held liable for the same.</li>
                    <li class="col-12 col-md-12 my-0 ml-0 pl-0">The Promoter specifically reserves the right to offer the property and/or the buildings and structures being constructed thereon or any part thereof (save and except the said Premises, as security to any other credit / financial institution, bank or other person/body, who has or may hereafter advance credit, finance or loans to the Promoter.</li>
                    <li class="col-12 col-md-12 my-0 ml-0 pl-0">In the event, the Applicant wishes to withdraw from/cancel this EOl for any reason whatsoever the pre-allotment deposit paid by the Applicant shall be returned within 30 days without any interest thereupon.</li>
                    <li class="col-12 col-md-12 my-0 ml-0 pl-0">All disputes to/arising out of this EOl are subject to the exclusive jurisdiction of Courts in Pune.</li>
                    <li class="col-12 col-md-12 my-0 ml-0 pl-0">The Applicant hereby expressly admits, acknowledges, and confirms that layout, landscaping pathway amenities, and facilities contained/given in any advertisement or brochure, by the Promoter and/or its agents to the applicant and/or his/her/their/its agents is just for the promotional purpose it is expressly understood and agreed by the Applicant that unless such amenities, layout, facilities are contained or incorporated in the agreement for sale, Promoter will not be bound to provide the same to the Applicant. The Promoter further reserves, at its sole discretion, to make such variations, additions, alterations, deletions, and/or modifications in plan and landscaping as stated in the Agreement for Sale as it may deem appropriate or as may be directed by the Competent Authority.</li>
                    <li class="col-12 col-md-12 my-0 ml-0 pl-0">The Applicant further declares that he has expressed his interest after reading and understanding and/or being explained and having understood the contents of the terms and conditions of EOl and its implications mentioned herein and undertakes not to raise any objections in that regard.</li>
                    <li class="col-12 col-md-12 my-0 ml-0 pl-0">I hereby agree to be bound by these terms and conditions. I hereby confirm that the details provided by me in this EOI form, are true and accurate.</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

        </div>
        <Transition name="right-container" >
        <div class="ct-col col-lg-4 right-container mob-hide" v-show="!isGifLoading">
          <!-- <div class="info-section"> -->
            <div class="info-wrapper">
              <div class="row No-padding">
                <div class="col-12">
                  <p>EOI Booking</p>
                  <h2 class="page-heading">Nirvana Bougainvilla</h2>
                  <p class="align-data">Khopoli, Maharashtra</p>
                </div>
            <!-- </div> -->
               <div class="card-info">
                <!-- <b-button variant="primary" >Generate EOI</b-button> -->
                <router-link @click.native="navigate" :to="{name:'masterplan',query:{...this.$route.query}}" class="btn btn-block generate-eoi" >Explore Now</router-link>
              </div>
              <!-- <div class="col-md-12 site-visit-section my-1">
                <div class="site-visit mt-0 mb-1" style="height: 200px;">
                  <div class="site-visit-strap text-center">
                    <img src="@/assets/images/play-btn.png" class="play-btn" v-b-modal.virtualTour>
                    <br><span class="site-visit-strap-text ">Watch Project Video</span>
                  </div>
                </div>
              </div> -->
              <EOIBenefits />
          <div class="col-12 powered-by">Powered by <span class="powered-by-ppistol ml-1">PropertyPistol&nbsp;Technologies</span></div>
          </div>
        </div>
        </div>
      </Transition>
      <div class="explore-fixed-button col-12 mob-show" v-if="isMobile && !isGifLoading"> 
        <DownArrow v-show="isMobile && isDownVisible"/>
        <div class="card-info">
         <router-link @click.native="navigate" :to="{name:'masterplan',query:{...this.$route.query}}" class="btn btn-block generate-eoi" >Explore Now</router-link>
       </div>
     </div>
      </div>
    </div>
    <VirtualTour />
    <VirtualTour id="locationTour" url="https://player.vimeo.com/video/861532068?h=8cf91d424e" />
    <VirtualTour id="flatTour" url="https://www.youtube.com/embed/EDuu0hS08TU?si=2L-mSNYfUPAxQBrR" />
    <EnquiryForm />
  </div>
</template>
<script>
import {masterJson, userTypeUrl} from '@/assets/scripts/utility.js';
import { Carousel, Slide } from 'vue-carousel';
import {FormSteps} from '@/assets/scripts/utility.js';
  export default {
    components: {
      ProgressBar: ()=>import("@/components/ProgressBar.vue"),
      MasterSVG: ()=>import('@/components/MasterSVG.vue'),
      Typologies: ()=>import('@/components/Home/Typologies.vue'),
      Carousel,
      Slide,
      EnquiryForm:()=>import('@/components/Home/EnquiryForm.vue'),
      VirtualTour:()=>import('@/components/VirtualTour.vue'),
      ProjectLocation: ()=>import('@/components/Home/ProjectLocation.vue'),
      DownArrow: ()=>import('@/components/Common/DownArrow.vue'),
      EOIBenefits: ()=>import('@/components/Home/EOIBenefits.vue')
    },
    data() {
      return {
        formSteps:FormSteps,
        prevLabel:"",
        show: false,
        nextLabel:"",
        isDownVisible:true,
        isNewCustomer: true,

      }
    },
    computed:{
      masterBuildings(){
			return masterJson.Buildings;
		},
      slideImages(){
        return masterJson.Buildings.filter(building=>!!building.image);
      },
      isLoading(){
        return this.$store.getters.isLoading
      },
      isGifLoading(){
        return this.$store.getters.isGifLoading;
      },
      isMobile(){
        return window && window.innerWidth<=991;
      }
    },
    watch:{
      isGifLoading(newValue){
        if(!newValue){
          this.$refs && this.$refs.carousel && this.$refs.carousel.$forceUpdate();
        }
      }
    },
    methods: {
      toggleNav(){
        $('#navbarSupportedContent').toggle();
      },
      scrollToSection(e){
        e.preventDefault();
        let targetsection = (e.target.getAttribute("href")).slice(1);
        let el = document.getElementById(targetsection);
        this.$refs[targetsection].scrollIntoView({behavior: "smooth"})
        $('#navbarSupportedContent').toggle();
      },
      downloadBrocher() {
        window.open('')
      },
      navigate(){
        this.$store.dispatch('setLoading',true);
      },
      getUserType() {
        if(this.$route.query.user) {
          let userId = atob(this.$route.query.user);
          this.$axios.defaults.headers.common['ngrok-skip-browser-warning'] = true;
          this.$axios.get(userTypeUrl+'?user_id='+userId).then((res)=> {
            if (res.data.user_type === 'EndUser') {
              this.isNewCustomer = false;
            }
          });
        }
      }
    },
    created(){
      let userId = this.$route.query.user_id;
      if(userId){
        userId = btoa(userId);
        this.$router.replace({query: {user:userId}});
        this.$store.dispatch('setUserId',userId);
        this.$cookies.set('nr-user-id',userId,'1d');
      }else if(this.$route.query.user){
        this.$store.dispatch('setUserId',this.$route.query.user);
      }else {
        const storedId = this.$cookies.get('nr-user-id');
        if(storedId){
          this.$store.dispatch('setUserId',storedId);
        }else{
          this.$store.dispatch('setUserId',null);
        }
      }
    },
    mounted() {
      this.getUserType();
      // let buildings = Object.assign({}, ...this.masterBuildings.filter(f => f.key === 'Wing 2'));
      // this.$store.dispatch("setTypology", buildings);
      // document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      //     anchor.addEventListener('click', function (e) {
      //         e.preventDefault();
      //         $('html, body').animate({
      //             scrollTop: $($.attr(this, 'href')).offset().top - 100 
      //         }, 300);
      //     }, {passive: true});
      // });
      // Initialize default plot selection for next screen
      // let buildings = Object.assign({}, ...this.masterBuildings.filter(f => f.key === 'Plot 67'))
      // this.$store.dispatch("setTypology", buildings);
    }
  }
</script>

<style scoped>

.skeletonShine {
  background: url(../assets/images/web.jpg);
  height: 100vh;
  width: 100vw;
  position: absolute;
  overflow: hidden;
  top: 0;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f5f5f5;
  z-index: 1034;
}
.skeletonShine .loader{
  display: none;
}
.skeletonShine:after {
  content: "";
  top: 0;
  transform: translateX(100%);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  animation: slide 1s infinite;
  background: -moz-linear-gradient( left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
 /* FF3.6+ */
  background: -webkit-gradient( linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(50%, rgba(255, 255, 255, 0.8)), color-stop(99%, rgba(128, 186, 232, 0)), color-stop(100%, rgba(125, 185, 232, 0)));
 /* Chrome,Safari4+ */
  background: -webkit-linear-gradient( left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
 /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient( left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
 /* Opera 11.10+ */
  background: -ms-linear-gradient( left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
 /* IE10+ */
  background: linear-gradient( to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8', GradientType=1);
 /* IE6-9 */
}

@keyframes slide {
  0% {
      transform: translateX(-100%);
 }
  100% {
      transform: translateX(100%);
 }
}

.title-container{
  background: linear-gradient(0deg, rgb(0 0 0 / 30%) 60%, rgba(25, 133, 136, 0.00) 100%);
}

@media (min-width: 992px){
  .title-container{
    margin-bottom: -70px;
    padding-bottom: 60px;
  }
}

#heroVideoBg{
  width: 100%;
}

.gap-8{
  gap: 8px;
}

.gallery-img{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 98%;
  height: 180px;
  border-radius: 4px;
}

.brand-logo-enter-active{
  transition: transform 0.5s ease-in;
  transform: translateY(0);
}
.brand-logo-enter {
  transform: translateY(-200px);
  opacity: 0;
}

.right-container-enter-active{
  transition: all 1s ease-in-out;
  transform: translateX(0);
  opacity: 1;
}
.right-container-enter {
  transform: translateX(200px);
  opacity: 0;
  position: static;
}

.image-cover{
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1034;
}

.image-cover-leave-from{
  opacity: 1;
}
.image-cover-leave-active{
  transition: all 0.75s cubic-bezier(0,.97,.54,1) 1s;
}
.image-cover-leave-to{
  opacity: 0;
}

.plan-holder{
  margin-top: 50px;
}
 .plan-holder img {
    height: 100%;
  }
.key-highlights {
   width: 30px;
   height: auto;
  left: 968px;
  top: 293px;
}
.powered-by {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  color: #00000080;
}
.powered-by .powered-by-ppistol{
  color: #000000;
}
.align-order {
  list-style-position: inside;
}
.generate-eoi {
  background: #0075C4;
  border-radius: 4px;
  font-size: 16px;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  font-weight: 600;
  padding: 15px 15px 16px 15px;
  box-shadow: none;
}
.generate-eoi:hover {
  background: #0692f0;
  color: #FFFFFF;
}

.pricing-variant-row{
  margin-top: 3rem;
}

.site-plan-img{
  height: auto;
}
.mob-show{
  display: none;
}
.No-padding {
  margin: 0;
  padding: 0;
}
.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  right: 0;
  left: 0;
}

.VueCarousel-dot-container {
  display: none !important;
}
.right-container {
  position: absolute;
  display: block;
  right: 0;
  height: 100vh;
  position: sticky;
  top: 0;
  padding: 14px;
  z-index: 1033;
  background: #FFFFFF;
}
.card-info {
  width: 100%;
  margin: 2px;
  background: #FFFFFF;
  border: 1px solid rgba(73, 72, 153, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  margin: 16px;
  padding: 16px;
}
 .imp-things {
  height: 16px;
  left: 46px;
  top: 1348px;

  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  align-items: center;
 }
  .info-section {
    /* background: url(../assets/images/about-bg.jpg) no-repeat center 40px; */
    /* padding: 15px; */
    /* border: 6px solid #fff; */
    border-top: none;
    z-index: 1;
    position: relative;
    background-size: cover;
    background: #FFFFFF;
    border: 1px solid rgba(73, 72, 153, 0.2);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 3%);
    border-radius: 10px;
    margin: 16px;
    /* margin-top: -40px; */
  }

  .info-wrapper {
    background: #fff;
    width: 100%;
    border-radius: 10px;
    display: flex;
  }

  .info-wrapper h2,
  .amenities-section h2 {
    font-size: 12px;
    font-weight: bold;
    color: #000;
    margin-bottom: 10px;
  }
     .info-wrapper .page-heading {
    font-size: 12px;
    font-weight: bold;
    color: #000;
    font-weight: 700;
    font-size: 24px;
  }

  .info-wrapper p {
    font-size: 14px;
    color: #00000080;
    line-height: 24px;    
    padding: 16px;
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 0;
  }

  .right-container .info-wrapper p{
    line-height: 16px;
    text-transform: uppercase;
  }
  .right-container .info-wrapper .align-data{
    text-transform: none;
  }
.info-wrapper .align-data {
    font-size: 13px;
    color: #000000;
    line-height: 16px;
    opacity: 0.5;
    text-transform: none;
    padding-left: 0px;
    padding-top: 0;
    margin-bottom: 0;
 }
  .about-section {
    background: #F5F5F5;
    border-radius: 10px !important;
    padding: 25px;
  }

.amenities-section{
  padding-left:30px;
}

.amenities-section.map-section{
  padding-top: 30px;
}

.amenities-section.terms-section{
  padding-top: 32px;
}

.amenities-section.terms-section .headding{
  margin-top: 0;
}

.amenities-section.key-highlights-section ul li{
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
}

.amenities-section .amenities-list{
  padding-left: 10px;
}

.amenities-section .amenities-list li{
  margin-bottom: 12px;
}
.scroll-fix{
  margin-top: -80px;
  padding-top: 85px;
  display: block;
}

.btn-map{
  color: #0075C4;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none !important;
}

  .amenities-section ul li,
  .amenities-section ol li {

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px; 
  }
  .sub-list>li{
    list-style-position: outside;
    list-style-type:disc;
    margin-left: 10px;
  }
  .amenities-section ul:not(.sub-list)>li{
    display:flex;
    list-style-type:none;
  }
.amenities-section ul:not(.sub-list)>li:before
{
  content: url('../assets/images/ret-dot.png');
  margin-right:6px;
  transform: translateY(-2px);
}
  .height-s-fix {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .brand-logo-mob {
    display: block;
    position: fixed;
    top: 0;
    z-index: 1033;
  }

  .text-underline-em{
    text-decoration: underline;
    font-weight: 500;
  }

  @media (max-width: 991px) {
    .info-wrapper {
      position: relative;
      border-radius: 0;
    }

    .side-menu {
      margin-top: 30px;
    }

    .info-section {
      /* background: url(../assets/images/about-bg.jpg) no-repeat center; */
      background-size: cover;
      margin: 0;
    }
    .plan-holder img{
      min-height: 320px;
      object-fit: cover;
    }
  }

  @media (max-width: 767px) {
    .brand-logo-mob {
      padding: 10px;
      padding-top: 0;
      display: block;
    }
  }
  .navbar
  {
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
  }
  .navbar-light .navbar-nav{
    margin-left: 132px;
  }
  .navbar-light .navbar-nav .nav-link,
  .download-brocher
{
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #000;
  padding-right: 14px;
  padding-left: 14px;
}

.key-highlights-section .download-brocher{
  margin-bottom: 22px;
  display: block;
  padding-left: 0;
  margin-left: -16px;
}
  @media (min-width: 1088px) and (max-width:1271px){
    .navbar-light .navbar-nav .nav-link{
      font-size: 11px;
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  @media (min-width: 992px) and (max-width:1087px){
    .navbar-light .navbar-nav .nav-link{
      font-size: 10px;
      padding-right: 8px;
      padding-left: 8px;
    } 
  }

.enquire-btn{
  border-radius: 4px;
  background: #2B2D42;
  padding: 6px 16px;
  color: #ffffff !important;
}

.info-wrapper .heading-box
{
width: 100%;
height: 56px;
border-radius: 10px 10px 0px 0px;
background: linear-gradient(270deg, rgba(2, 103, 193, 0.1) 54.17%, rgba(2, 103, 193, 0.00) 100%);
border-radius: 10px 10px 0px 0px;
padding:10px;
padding-top: 16px;
padding-left:20px;

}

.info-wrapper .heading-box h2, .amenities-section h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #2B2D42;
  opacity: 1;
}

.info-wrapper .heading-box h2.terms-heading{
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
}

.info-wrapper .heading-box .heading-subtitle{
  font-size: 12px;
  color: #00000080;
  padding-left: 10px;
}
.heading-box h2 span
{
  font-weight:600;
  padding-left:3px;
}
.about-new
{
  margin-top:-134px;
  position:relative;
}

.home-progress-bar{
  margin-bottom: 20px;
  margin-left: 32px;
  margin-right: 32px;
}
.about-new h3
{
font-style: normal;
font-weight: 700;
font-size: 34px;
line-height: 41px;
color: #FFFFFF;
padding-bottom: 20px;
}
.about-new h4
{
font-style: normal;
font-weight: 500;
font-size: 20px;
color: #FFFFFF;
}
.highlights-box{

background:url(../assets/images/highligt-box.png);
width:100%;
height:140px;
background-size:100% 100%;
margin-top:10px;
}
.highlight-title{
  margin-top: 20px;
}
.highlights-box img
{
  height:50px;
  width:50px;

}
.highlights-box p 
{
font-style: normal;
font-weight: 600;
font-size: 14px;
color: #494899;
opacity:1;
}
.highlights-box-ul  ul>li
{
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 20px;
list-style-type:none;
margin-left:-40px;
margin-top:20px

}
.highlights-box-ul ul li:before {
    content: url('../assets/images/ret-dot.png');
    margin-right:6px;
}

@media screen and (max-width: 1360px){
  .highlights-box{
    height: 190px;
  }

  .highlights-box .highlight-title {
    padding-right: 0 !important;
  }
}

.location-subtitle{
  color: #2B2D42;
  font-size: 15px !important;
  font-weight: 500;
}
.download-bro
{
 font-style: normal;
 font-weight: 500;
 font-size: 13px;
 line-height: 20px;
 color: #AB3E91;
 opacity:1;
 display: flex;
 align-items: center;
 cursor: pointer;
}
.heading-box .nav-pills .nav-link, .nav-pills .show > .nav-link 
{
  
    background: #ED7123;
    font-size: 13px;
line-height: 16px;
    color: #FFFFFF;
    background-color:#494899;
  padding:5px 20px;
  opacity: 0.5;
  border-radius:0px
}
.heading-box .nav-pills .nav-link.active, .nav-pills .show > .nav-link 
{
    opacity: 1;
}
.nav-pills .nav-item:last-child .nav-link {
    border-radius: 0px 4px 4px 0px;
    border: none;
}
.nav-pills .nav-item:first-child .nav-link {
    border-radius: 4px 0px 0px 4px;
    border: none;
}
.sf-text
{
font-style: normal;
font-weight: 600;
font-size: 13px;
line-height: 16px;
color: #AB3E91;
padding-top:10px;
}
.site-visit
{
  margin-top: 18px;
  background:url(../assets/images/video-bg.png);
  height:160px;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  border-radius: 4px;
}

.site-visit.location{
  background:url(../assets/images/video-location-bg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.site-visit.tour{
  background:url(../assets/images/video-tour-bg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.site-visit-strap
{
  position: absolute;
  width: 100%;
  height: 48px;
  top: 56%;
  left: 0;
  background: rgba(0, 0, 0, 0.22);
  backdrop-filter: blur(42px);
}
.play-btn
{
  width: 50px;
  margin-top: -108px;
}
.site-visit-strap-text
{
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  position: relative;
  top: -10px;
}
.tnc .amenities-section .headding
{
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  margin-bottom: 8px;
}

.navbar-toggler
{
  position:absolute;
  right:3px;
  top:10px;
  padding: 2px 6px;
  font-size: 20px;
  border: none;
  background: #ffffff;
}
.navbar-collapse{
  margin-right: -16px;
}
.navbar-collapse .navbar-nav{
  background: #ffffff;
}
.mb-20{
  margin-bottom: 20px;
}
@media(max-width:768px)
{
  .navbar
  {
    width:100%;
    height:50px;
  }

  .about-new
  {
    position: relative;
  }

}

@media(max-width:576px){
  .about-new{
    margin-top: -47px !important;
  }
  .about-new h3{
    font-size: 22px !important;
    line-height: 32px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) { 


 .navbar
  {
    height:50px;
  }
  .navbar-toggler{
    right: 10px;
  }
  .download-brochure-nav{
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

@media (max-width:991px){

  .navbar-light .navbar-nav{
    padding: 30px 0;
    margin-left: -16px;
  }

  .mob-hide{
    display: none;
  }
  .image-cover{
    object-fit: cover;
  }
  .mob-show{
    display: revert;
  }
  .explore-fixed-button{
    position: fixed;
    bottom: 0;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    padding: 0;
  }

  .explore-fixed-button .card-info{
    margin: 0;
    border-radius: 0;
  }
  .generate-eoi{
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .about-new{
    margin-top: -52px;
  }
  .about-new h3{
    font-size: 26px;
    padding-bottom: 20px;
    line-height: 1;
  }
  
  .about-new h4{
    padding-bottom: 20px;
  }
  .amenities-section ul:not(.sub-list)>li{
    display: flex;
  }
  .amenities-section .download-brocher-section{
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
  .mb-mob-20{
    margin-bottom: 20px;
  }
  .amenities-section.pricing-section{
    padding-left: 62px;
    padding-right: 62px;
  }
  .amenities-section.site-visit-section{
    padding-left: 0;
    padding-right: 0;
  }
  .pricing-variant-container{
    margin-bottom: 70px;
  }
  .pricing-variant-container img{
    margin-bottom: 12px;
  }
  .pricing-variant-row{
    margin-top: 0;
  }
  .amenities-section.map-section{
    padding-left: 0;
    padding-right: 0;
  }
  .tab-content{
    margin-bottom: 0 !important;
  }
  .tnc{
    padding-bottom: 130px;
  }
  .download-brochure-nav{
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .highlights-box.lower-box{
    margin-top: 20px;
  }

  .info-wrapper .heading-box h2, .amenities-section h2{
    font-size: 18px;
  }

}

</style>